import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const UseMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const handleChange = () => setMatches(mediaQuery.matches);
    handleChange();
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
};

const ServiceCard = ({ service, index }) => {
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: (custom) => ({
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delay: custom * 0.5, // Stagger delay based on index
      },
    }),
  };

  const customHeadingStyle = () => {
    const isLargeScreen = UseMediaQuery('(min-width: 768px)');

    if (
      service.heading ===
        'Capacitors, Harmonics & Reactive power compensation studies' ||
      service.heading === 'Loadshedding and power management systems'
    ) {
      return {
        position: 'relative',
        bottom: isLargeScreen ? '0.7rem' : '0.3rem', // Adjust based on screen size
      };
    }

    return {
      position: 'relative',
      top: isLargeScreen ? '0.3rem' : '0.3rem', // Adjust based on screen size
    };
  };

  return (
    <motion.div
      custom={index}
      variants={cardVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }} // Triggers animation once at 10% visibility
      whileHover={{ scale: 1.05 }}
      className="relative overflow-hidden rounded-lg shadow-lg h-72 group"
    >
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out group-hover:scale-105"
        style={{
          backgroundImage: `url(${require(`../assets/images/${service.image}`)})`,
        }}
      />
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-20 group-hover:opacity-50 transition-opacity duration-300 ease-in-out" />

      {/* Content Section */}
      <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-75 transition-all duration-300 ease-in-out p-4 max-h-[4rem] group-hover:max-h-full">
        {/* Service Heading */}
        <h3
          style={customHeadingStyle()} // Correctly invoking the style function
          className="sm:text-lg font-semibold text-white mb-0 group-hover:mb-2 transition-all duration-300 ease-in-out ${
    service.heading === 'Protection relay programming' ? 'whitespace-nowrap' : ''"
        >
          {service.heading}
        </h3>

        {/* Hidden Content - Revealed on Hover */}
        <ul className="text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out list-disc pl-5">
          {service.bulletPoints.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default ServiceCard;
